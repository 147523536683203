<template>
  <div class="modify">
    <el-page-header @back="$router.back()" :content="this.test.name">
    </el-page-header>
    <el-row>
      <el-table
          ref="multipleTable"
          :data="testPaper"
          tooltip-effect="dark"
          @selection-change="deleteSelectionChange"
          style="width: 100%">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>

        <!--        <el-table-column type="expand">-->
        <!--          <template slot-scope="props">-->
        <!--            <show-paper :one="props.row"></show-paper>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="paperTopic"
            label="文章标题">
        </el-table-column>

        <el-table-column
            label="类别"
            width="180"
        >
          <template slot-scope="scope">
            {{ getTagName(scope.row.paperType) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="paperRank"
            width="50"
            label="难度">
        </el-table-column>
        <el-table-column
            prop="tags"
            label="标签信息">
          <template slot-scope="scope">
            <little-tag :text="scope.row.tags"></little-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="gmtCreate"
            width="150"
            label="创建时间">
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="delete">
      <el-button @click="clickDelete" type="danger">删除</el-button>
    </el-row>

    <el-divider>根据下方的题目选择</el-divider>
    <el-row type="flex">
      <el-radio-group v-model="choose">
        <el-radio-button :label="1">全部</el-radio-button>
        <el-radio-button :label="2">自己</el-radio-button>
      </el-radio-group>
    </el-row>
    <el-row type="flex" class="delete">
      <el-button @click="clickAdd" type="primary">添加</el-button>
    </el-row>
    <el-row>
      <el-table
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          :data="paper.records"
          style="width: 100%">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <show-paper :one="props.row"></show-paper>
          </template>
        </el-table-column>
        <el-table-column
            prop="paperTopic"
            label="文章标题">
        </el-table-column>

        <el-table-column
            label="类别"
            width="180"
        >
          <template slot-scope="scope">
            {{ getTagName(scope.row.paperType) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="paperRank"
            width="50"
            label="难度">
        </el-table-column>
        <el-table-column
            prop="tags"
            label="标签信息">
          <template slot-scope="scope">
            <little-tag :text="scope.row.tags"></little-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="gmtCreate"
            width="150"
            label="创建时间">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :current-page="paper.current"
          @current-change="getTableData"
          layout="prev, pager, next"
          :total="paper.pages">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import global from "@/config/global";
import littleTag from "@/components/tags/little-tag";
import {getPaperAll} from "@/api/paper";
import showPaper from "@/views/paper/common/show-paper";
import {getTestById} from "@/api/test";
import {addTp, deleteTp, selectAllPaperByTid, selectAllTest} from "@/api/tp";

export default {
  name: "modify-paper",
  data() {
    return {
      choose: 1,
      paper: {},
      testPaper: [],
      test: {},
      options: global.options,
      id: this.$route.query.id,
      multipleSelection: [],
      deleteSelection: []
    }
  },
  components: {
    littleTag,
    showPaper
  },
  mounted() {
    this.getTest()
    this.getTableData(1)
  },
  methods: {
    clickAdd() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          type: "error",
          message: "请填写内容"
        })
        return;
      }
      let tp = {
        tid: this.id,
        ids: []
      }
      this.multipleSelection.forEach(function (item) {
        tp.ids.push(item.id)
      });
      addTp(tp).then(result => {
        this.$message({
          type: "success",
          message: result.data.tp
        })
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message
        })
      })
    },
    clickDelete() {
      if (this.deleteSelection.length <= 0) {
        this.$message({
          type: "error",
          message: "请勾选内容"
        })
        return;
      }
      let ids = []
      this.deleteSelection.forEach(function (item) {
        ids.push(item.tpId);
      })
      console.log(ids)
      deleteTp(ids).then(result => {
        this.$message({
          type: "success",
          message: result.data.tp
        })
        this.getTest();
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message
        })
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteSelectionChange(val) {
      this.deleteSelection = val;
      // console.log(this.deleteSelection)
    },
    getTest() {
      selectAllPaperByTid(this.id).then(result => {
        console.log(result)
        // this.test = result.data.test.test;
        // this.testPaper = result.data.test.paperList;
        this.testPaper = result.data.tp;
      }).catch(error => {
        this.$message.error(error.message)
      })
    },
    getTableData(number) {
      let userId = ""
      if (this.choose === 2) {
        userId = this.$store.state.user.id;
      }
      selectAllTest(userId, this.id, number).then(result => {
        console.log(result)
        this.paper = result.data.tp;
      })
    },
    getTag(index) {
      return this.options[index].label;
    },
    getTagName(type) {
      if (type === 1) {
        return "选择题"
      } else if (type === 2) {
        return "判断题"
      } else if (type === 3) {
        return "填空题"
      } else {
        return "简答题"
      }
    }
  },
  watch: {
    choose() {
      this.getTableData(1);
    }
  }
}
</script>

<style scoped>
.modify {
  padding: 10px;
  background-color: #fff;
}

.delete {
  margin: 10px auto;
}
</style>